<template>
  <v-card class="mx-auto" max-width="400">
    <v-img class="white--text align-end" height="200px" :src="getSrc(Icon)">
      <v-card-title>{{ Title }}</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0"> Price From £{{ Price }} </v-card-subtitle>

    <v-card-text class="text--primary">
      <div>{{ Description }}</div>
    </v-card-text>

    <v-card-actions>
      <!-- <v-btn color="orange" text> Share </v-btn> -->

      <v-btn
        color="orange"
        text
        @click="
          addToCart(id);
          $emit('added', `${Title}`);
        "
      >
        Add To Cart
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<!--template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      color="grey lighten-4"
      max-width="600"
    >
      <v-img
        :aspect-ratio="16/9"
        src="https://cdn.vuetifyjs.com/images/cards/kitchen.png"
      >
        <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
            style="height: 100%;"
          >
            $14.99
          </div>
        </v-expand-transition>
      </v-img>
      <v-card-text
        class="pt-6"
        style="position: relative;"
      >
        <v-btn
          absolute
          color="orange"
          class="white--text"
          fab
          large
          right
          top
        >
          <v-icon>mdi-cart</v-icon>
        </v-btn>
        <div class="font-weight-light grey--text text-h6 mb-2">
          For the perfect meal
        </div>
        <h3 class="text-h4 font-weight-light orange--text mb-2">
          QW cooking utensils
        </h3>
        <div class="font-weight-light text-h6 mb-2">
          Our Vintage kitchen utensils delight any chef.<br>
          Made of bamboo by hand
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template-->

<script>
import { ADD_TO_CART } from "@/store/storefunctions.js";
import { mapActions } from "vuex";
export default {
  props: ["id", "Title", "Price", "Description", "Icon"],
  name: "ServiceCard",
  methods: {
    getSrc(title) {
      return require(`../assets/services/img/${title}.jpg`);
    },
    ...mapActions({
      addToCart: ADD_TO_CART,
    }),
    addCartAction(id) {
      this.addToCart(id);
      //$emit('added', this.{title}`');
    },
  },
};
</script>

<style>
/* .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
} */
</style>
