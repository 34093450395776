<template>
  <tr class="shopping-cart">
    <td>
      <v-img max-height="64" max-width="64" :src="getSrc(Icon)"></v-img>
    </td>
    <td>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>{{ Title }} Service</v-list-item-title>
          <!-- <v-list-item-subtitle>{{ Desc }}</v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
    </td>
    <td>
      <h5>{{ DisplayPrice }}</h5>
    </td>
    <td>
      <v-text-field
        dense
        append-outer-icon="mdi-plus"
        prepend-icon="mdi-minus"
        :value="Quantity"
        filled
        type="text"
        @click:append-outer="$emit('plus', Id)"
        @click:prepend="$emit('minus', Id)"
        >{{ Quantity }}</v-text-field
      >
    </td>
    <!-- <td>
      <v-btn class="mx-2" small fab dark color="red">
        <v-icon dark> mdi-trash-can-outline </v-icon>
      </v-btn>
    </td> -->
    <td class="text-right">
      <h4>£{{ (Price * Quantity).toFixed(2) }}</h4>
      <!-- <div class="product-line-price"></div> -->
    </td>
  </tr>
</template>
<script>
export default {
  name: "CartItem",
  props: ["Title", "Icon", "Desc", "Id", "Quantity", "Price", "DisplayPrice"],
  methods: {
    getSrc(title) {
      return require(`../assets/services/img/${title}.jpg`);
    },
  },
};
</script>
