export default {
  services: [
    {
      id: 1,
      icon: "carpet",
      title: "Carpet Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£20.00",
      price: 20,
      time: 60,
    },
    {
      id: 2,
      icon: "rug",
      title: "Rug Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£25.00",
      price: 25,
      time: 90,
    },
    {
      id: 3,
      icon: "stairs",
      title: "Stairs Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£10.00",
      price: 10,
      time: 10,
    },
    {
      id: 4,
      icon: "floor",
      title: "Floor Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£15.00",
      price: 15,
      time: 10,
    },
    {
      id: 5,
      icon: "guttering",
      title: "Gutters Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£80.00",
      price: 80,
      time: 90,
    },
    {
      id: 6,
      icon: "roof",
      title: "Roof Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£120.00",
      price: 120,
      time: 150,
    },
    {
      id: 7,
      icon: "hoover",
      title: "Hoovering",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£15.00",
      price: 15,
      time: 10,
    },
    {
      id: 8,
      icon: "sofa",
      title: "Sofa and Beds Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£50.00",
      price: 50,
      time: 40,
    },
    {
      id: 9,
      icon: "window",
      title: "Window Cleaning",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      display_price: "£60.00",
      price: 60,
      time: 30,
    },
  ],
};
