<template>
  <v-row style="margin-top: 128px">
    <v-col sm="12" md="8"
      ><v-sheet rounded="lg" min-height="50vh" elevation="1" class="pa-10">
        <h4>Reservation Details</h4>
        <v-divider></v-divider>
        <template>
          <v-card
            class="overflow-hidden mt-5"
            color="light-blue lighten-5"
            light
          >
            <v-toolbar flat color="light-blue accent-1">
              <v-icon>mdi-account</v-icon>
              <v-toolbar-title class="font-weight-light">
                Client Information
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-select
                label="Title"
                v-model="cusTitle"
                :items="titlesLst"
                :error-messages="cusTitleErrors"
                @input="$v.cusTitle.$touch()"
                @blur="$v.cusTitle.$touch()"
              ></v-select>
              <v-text-field
                label="First Name"
                clearable
                type="text"
                v-model="cusName"
                :error-messages="cusNameErrors"
                @input="$v.cusName.$touch()"
                @blur="$v.cusName.$touch()"
              ></v-text-field>
              <v-text-field
                label="Surname"
                clearable
                type="text"
                v-model="cusLastName"
                :error-messages="cusLastNameErrors"
                @input="$v.cusLastName.$touch()"
                @blur="$v.cusLastName.$touch()"
              ></v-text-field>
              <v-text-field
                label="Mobile"
                type="tel"
                v-model="mob"
                :error-messages="mobErrors"
                @input="$v.mob.$touch()"
                @blur="$v.mob.$touch()"
              ></v-text-field>
              <v-text-field
                label="Landline"
                type="tel"
                v-model="landline"
                :error-messages="landlineErrors"
                @input="$v.landline.$touch()"
                @blur="$v.landline.$touch()"
              ></v-text-field>
              <v-text-field
                label="Email"
                type="email"
                v-model="email"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </template>
        <DateTimeSelect />
        <template><PropertyAddress /></template>
        <template>
          <v-card
            class="overflow-hidden mt-5"
            color="light-blue lighten-5"
            light
            style="display: none"
          >
            <v-toolbar flat color="light-blue accent-1">
              <v-icon>mdi-clipboard-text-clock</v-icon>
              <v-toolbar-title class="font-weight-light">
                Property Details (where Services are required)
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-input
                    label="Appointment Date and Time"
                    style="border-bottom: 1px"
                    ><input
                      v-model="appdt"
                      :min="tomo"
                      ref="datetimeselector1"
                      type="datetime-local"
                  /></v-input>
                  <v-btn @click="$refs.datetimeselector1.showPicker()"
                    >open</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="appdt"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appdt"
                        label="Appointment Date and Time"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="(on, testFunc())"
                        @click="testFunc()"
                      ></v-text-field>
                    </template>
                    <input
                      v-model="appdt"
                      :min="tomo"
                      ref="datetimeselector"
                      type="datetime-local"
                      @input="$refs.menu3.save(appdt)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="dateErrors"
                        @input="$v.date.$touch()"
                        @blur="$v.date.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      :min="tomo"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <!--v-text-field label="Date" type="date" min></v-text-field
                --></v-col>
                <!-- <v-col cols="6" sm="6"
                  ><v-text-field label="Time" type="time"></v-text-field
                ></v-col> -->
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="time"
                      class="mt-4 mr-3"
                      type="time"
                      label="Time"
                      append-icon="mdi-clock-outline"
                      readonly
                      v-on="on"
                      :error-messages="timeErrors"
                      @input="$v.time.$touch()"
                      @blur="$v.time.$touch()"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    :allowed-hours="allowedHours"
                    :allowed-minutes="allowedStep"
                    class="mt-1"
                    @click:minute="$refs.menu2.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-row>
              <!--input
                label="Appointment Date and time"
                type="datetime-local"
                :min="tomo"
              /-->
              <v-text-field
                label="House number and street name"
                type="text"
                v-model="addline1"
                :error-messages="addline1Errors"
                @input="$v.addline1.$touch()"
                @blur="$v.addline1.$touch()"
              ></v-text-field>
              <v-text-field
                label="House number and street name"
                type="text"
                v-model="addline1"
                :error-messages="addline1Errors"
                @input="$v.addline1.$touch()"
                @blur="$v.addline1.$touch()"
              ></v-text-field>
              <v-text-field
                label="Locality name"
                type="text"
                v-model="addline2"
                :error-messages="addline2Errors"
                @input="$v.addline2.$touch()"
                @blur="$v.addline2.$touch()"
              ></v-text-field>
              <v-text-field
                label="Town"
                type="text"
                v-model="town"
                :error-messages="townErrors"
                @input="$v.town.$touch()"
                @blur="$v.town.$touch()"
              ></v-text-field>
              <v-text-field
                label="Postcode"
                type="text"
                v-model="postcode"
                :error-messages="postcodeErrors"
                @input="$v.postcode.$touch()"
                @blur="$v.postcode.$touch()"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </template> </v-sheet
    ></v-col>
    <v-col sm="12" md="4"
      ><v-sheet rounded="lg" min-height="50vh" elevation="1" class="pa-5">
        <h4>Reservation Completion</h4>
        <v-icon color="success" x-large v-if="custInfo">mdi-check</v-icon>
        <v-icon color="red" x-large v-else>mdi-close</v-icon>
        Client Information
        <br />
        <v-icon color="success" x-large v-if="bookTime">mdi-check</v-icon>
        <v-icon color="red" x-large v-else>mdi-close</v-icon> Booking Date, Time
        <br />
        <v-icon color="success" x-large v-if="addressInfo">mdi-check</v-icon>
        <v-icon color="red" x-large v-else>mdi-close</v-icon>Property Address

        <div>
          <v-btn
            large
            color="orange"
            dark
            class="mt-15 float_left"
            to="/cartview"
            ><v-icon>mdi-arrow-left</v-icon>Basket</v-btn
          >
          <v-btn
            large
            color="green"
            dark
            class="mt-15 ml-2 float_right"
            @click="proceedToPayment()"
            >Payment <v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </div>
      </v-sheet></v-col
    >
  </v-row>
</template>

<script>
import {
  required,
  email,
  numeric,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import {
  SET_CUSTOMER_INFO,
  SET_BOOKING_INFO,
  IS_ADRRESS_INFO_VALID,
  IS_DATETIME_INFO_VALID,
} from "@/store/storefunctions.js";
import { mapActions, mapGetters } from "vuex";
import PropertyAddress from "@/components/PropertyAddress.vue";
import DateTimeSelect from "@/components/DateTimeSelect.vue";

export default {
  name: "BookingView",
  components: { PropertyAddress, DateTimeSelect },
  data: function () {
    return {
      date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10),
      menu1: false,
      time: null,
      menu2: false,
      menu3: false,
      appdt: null,
      //form inputs
      cusTitle: null,
      cusName: null,
      cusLastName: null,
      mob: null,
      landline: null,
      email: null,

      addline1: null,
      addline2: null,
      town: null,
      postcode: null,

      titlesLst: [
        "Master",
        "Miss",
        "Mr",
        "Mrs",
        "Ms",
        "Baron",
        "Dr",
        "Duke",
        "Lady",
        "Lord",
        "Madame",
        "Prof.",
        "Sir",
      ],
    };
  },
  validations: {
    cusTitle: { required },
    cusName: { required, maxLength: maxLength(75) },
    cusLastName: { required, maxLength: maxLength(75) },
    mob: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11),
    },
    landline: { numeric, minLength: minLength(11), maxLength: maxLength(11) },
    email: { required, email, maxLength: maxLength(75) },
    date: {
      required,
      minValue(val) {
        return new Date(val) > new Date();
      },
    },
    time: { required },

    addline1: { required, maxLength: maxLength(75) },
    addline2: { maxLength: maxLength(75) },
    town: { required, maxLength: maxLength(75) },
    postcode: { required, minLength: minLength(6), maxLength: maxLength(9) },
  },
  methods: {
    allowedHours: (v) => v >= 6 && v <= 23,
    allowedStep: (m) => m % 15 === 0,
    ...mapActions({
      saveCustomerInfo: `${SET_CUSTOMER_INFO}`,
      saveBookingInfo: `${SET_BOOKING_INFO}`,
    }),
    proceedToPayment() {
      //do input validations
      //save customer info
      this.saveCustomerInfo({
        title: this.cusTitle,
        name: this.cusName,
        lastname: this.cusLastName,
        mobile: this.mob,
        landline: this.landline,
        email: this.email,
      });
      //save booking info
      /* this.saveBookingInfo({
        date: this.date,
        time: this.time,
        street: this.addline1,
        locality: this.addline2,
        town: this.town,
        postcode: this.postcode,
      });*/
      this.$router.push({ path: "/paymentview" });
    },
    testFunc() {
      /*console.log("test Function works");
      // console.log(this.$refs);
      console.log(this.$refs["menu3"]);*/
    },
    //change address Validity on change
    addressValidity(val) {
      this.addressInfo = val;
    },
  },
  /* mounted() {
    this.$nextTick(() => {
      let dts = "datetimeselector1";
      console.log(this.$refs[dts]);
    });
  },*/
  computed: {
    tomo() {
      var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      return currentDate.toISOString().substring(0, 10);
    },
    ...mapGetters({
      addressInfo: `${IS_ADRRESS_INFO_VALID}`,
      bookTime: `${IS_DATETIME_INFO_VALID}`,
    }),
    //form validation errors
    cusTitleErrors() {
      const errors = [];
      if (!this.$v.cusTitle.$dirty) return errors;
      !this.$v.cusTitle.required && errors.push("First Name is required.");
      return errors;
    },
    cusNameErrors() {
      const errors = [];
      if (!this.$v.cusName.$dirty) return errors;
      !this.$v.cusName.required && errors.push("First Name is required.");
      !this.$v.cusName.maxLength &&
        errors.push("First Name is too long. Max 75 characters allowed.");
      return errors;
    },
    cusLastNameErrors() {
      const errors = [];
      if (!this.$v.cusLastName.$dirty) return errors;
      !this.$v.cusLastName.required && errors.push("Surname is required.");
      !this.$v.cusLastName.maxLength &&
        errors.push("Surname is too long. Max 75 characters allowed.");
      return errors;
    },
    mobErrors() {
      const errors = [];
      if (!this.$v.mob.$dirty) return errors;
      !this.$v.mob.required && errors.push("Mobile number is required.");
      !this.$v.mob.numeric && errors.push("Only numbers allowed.");
      !this.$v.mob.minLength &&
        errors.push("Mobile number is too short. Min 11 characters requred.");
      !this.$v.mob.maxLength &&
        errors.push("Mobile number is too long. Max 11 characters allowed");
      return errors;
    },
    landlineErrors() {
      const errors = [];
      if (!this.$v.mob.$dirty) return errors;
      !this.$v.landline.numeric && errors.push("Only numbers allowed.");
      !this.$v.landline.minLength &&
        errors.push("Landline number is too short. Min 11 characters requred.");
      !this.$v.landline.maxLength &&
        errors.push("Landline number is too long. Max 11 characters allowed");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("E-mail is required.");
      !this.$v.email.email && errors.push("E-mail address format invalied.");
      !this.$v.email.maxLength &&
        errors.push("E-mail address is too Long. Max 75 characters allowed.");
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("Appointment date is required.");
      !this.$v.date.minValue && errors.push("Please select a valid date");
      return errors;
    },
    timeErrors() {
      const errors = [];
      if (!this.$v.time.$dirty) return errors;
      !this.$v.time.required && errors.push("Appointment time is required.");
      return errors;
    },
    addline1Errors() {
      const errors = [];
      if (!this.$v.addline1.$dirty) return errors;
      !this.$v.addline1.required &&
        errors.push("First line of address is required.");
      !this.$v.addline1.maxLength &&
        errors.push(
          "First line of address is too Long. Max 75 characters allowed."
        );
      return errors;
    },
    addline2Errors() {
      const errors = [];
      if (!this.$v.addline2.$dirty) return errors;
      !this.$v.addline2.maxLength &&
        errors.push(
          "Second line of address is too Long. Max 75 characters allowed."
        );
      return errors;
    },
    townErrors() {
      const errors = [];
      if (!this.$v.town.$dirty) return errors;
      !this.$v.town.required && errors.push("Town is required.");
      !this.$v.town.maxLength &&
        errors.push("Town name is too long. Max 75 characters allowed.");
      return errors;
    },
    postcodeErrors() {
      const errors = [];
      if (!this.$v.postcode.$dirty) return errors;
      !this.$v.postcode.required && errors.push("Postcode is required.");
      !this.$v.postcode.maxLength &&
        errors.push("Postcode is too long. Max 8 characters allowed.");
      return errors;
    },
    //error summary
    custInfo() {
      if (
        !this.$v.cusTitle.$invalid &&
        !this.$v.cusName.$invalid &&
        !this.$v.cusLastName.$invalid &&
        !this.$v.mob.$invalid &&
        !this.$v.landline.$invalid &&
        !this.$v.email.$invalid
      ) {
        return true;
      }
      return false;
    },
    bookingInfo() {
      if (!this.$v.date.$invalid && !this.$v.time.$invalid) {
        return true;
      }
      return false;
    },
    // addressInfo() {
    //   if (
    //     !this.$v.addline1.$invalid &&
    //     !this.$v.addline2.$invalid &&
    //     !this.$v.town.$invalid &&
    //     !this.$v.postcode.$invalid
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
};
</script>

<style>
input[type="datetime-local"] {
  visibility: hidden;
  position: absolute;
}
</style>
