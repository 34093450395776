<template>
  <v-row style="margin-top: 128px">
    <v-col sm="12" md="8"
      ><v-sheet
        rounded="lg"
        min-height="50vh"
        color="white"
        elevation="1"
        class="pa-10"
      >
        <h4>Services Required</h4>
        <v-simple-table v-if="cartIdQts">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left"></th>
                <th class="text-left">Price</th>
                <th class="text-center">Quantity</th>
                <!-- <th class="text-left"></th> -->
                <th class="text-left">Estimated Total</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in cartIdQts" :key="item.title">
              <CartItem
                :Id="item.id"
                :Icon="item.icon"
                :Title="item.title"
                :Desc="item.desc"
                :Quantity="item.quantity"
                :Price="item.price"
                :DisplayPrice="item.display_price"
                @plus="increase"
                @minus="decrease"
              />
              <v-divider v-if="index != cartIdQts.length - 1"></v-divider>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="!emptyCart">
          <h4>No services in the cart. Please add some services first.</h4>
        </div>
      </v-sheet></v-col
    >
    <v-col sm="12" md="4"
      ><v-sheet rounded="lg" min-height="50vh" elevation="1" class="pa-5">
        <h4>Total</h4>
        <div class="totals mb-10" v-if="!emptyCart">
          <p>Cart empty. Please add services to cart to view totals.</p>
        </div>
        <div class="totals mb-10" v-if="emptyCart">
          <div class="totals-item">
            <label>Estimated Total</label>
            <div class="totals-value" id="cart-subtotal">
              £{{ cartTotal.toFixed(2) }}
            </div>
          </div>
          <!--div class="totals-item">
            <label>VAT (20%)</label>
            <div class="totals-value" id="cart-tax">
              £{{ (cartTotal * 0.2).toFixed(2) }}
            </div>
          </div-->
          <div class="totals-item">
            <label>2% Debit/Credit Card Fee</label>
            <div class="totals-value" id="cart-shipping">
              £{{ (cartTotal * 0.2).toFixed(2) }}
            </div>
          </div>
          <div class="totals-item">
            <label>Grand Total</label>
            <div class="totals-value" id="cart-shipping">
              £{{ (cartTotal * 1.2).toFixed(2) }}
            </div>
          </div>
          <div class="totals-item totals-item-total">
            <label>Reservation Deposit Required</label>
            <div class="totals-value" id="cart-total">£50.00</div>
          </div>
        </div>
        <div>
          <v-btn large color="orange" dark class="mt-15 float_left" to="/"
            ><v-icon>mdi-arrow-left</v-icon>Services</v-btn
          >
          <v-btn
            v-if="emptyCart"
            large
            color="success"
            dark
            class="mt-15 ml-2 flaot_right"
            to="/bookingview"
            >Booking <v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </div>

        <!-- <v-btn large block color="green" dark class="mt-15" to="/bookingview"
          >Continue</v-btn
        > -->
      </v-sheet></v-col
    >
  </v-row>
</template>

<script>
import CartItem from "@/components/CartItem.vue";
import {
  GET_CART_ITEMS_FULL,
  CART_TOTAL_VALUE,
  ADD_TO_CART,
  REMOVE_ITEM,
} from "@/store/storefunctions.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CheckoutBasket",
  components: { CartItem },
  methods: {
    ...mapActions({
      addToCart: ADD_TO_CART,
      removeFromCart: REMOVE_ITEM,
    }),
    increase(ItemID) {
      this.addToCart(ItemID);
    },
    decrease(ItemID) {
      this.removeFromCart(ItemID);
    },
  },
  computed: {
    ...mapGetters({
      cartIdQts: `${GET_CART_ITEMS_FULL}`,
      cartTotal: `${CART_TOTAL_VALUE}`,
    }),
    emptyCart() {
      if (this.cartIdQts.length) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
/* Totals section */
.totals .totals-item {
  float: right;
  clear: both;
  width: 100%;
  margin-bottom: 10px;
}
.totals .totals-item label {
  float: left;
  clear: both;
  width: 79%;
  text-align: right;
}
.totals .totals-item .totals-value {
  float: right;
  width: 21%;
  text-align: right;
}
.totals .totals-item-total {
  /* font-family: "HelveticaNeue-Medium", "Helvetica Neue Medium"; */
  font-weight: 700;
}
.totals .totals-item label {
  width: 60%;
}
.totals .totals-item .totals-value {
  width: 40%;
}
</style>
