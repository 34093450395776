<template>
  <v-card class="overflow-hidden mt-5" color="light-blue lighten-5" light>
    <!-- <v-toolbar flat color="light-blue accent-1"> -->
    <v-toolbar flat color="light-blue accent-1">
      <v-icon>mdi-clock</v-icon>
      <v-toolbar-title class="font-weight-light">
        Appointment Date Time Details (When Services are required)
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-input label="Appointment Date and Time" style="border-bottom: 1px"
            ><input
              v-model="appdt"
              :min="tomo"
              ref="datetimeselector1"
              type="datetime-local"
              @change="dtChanged"
          /></v-input>
          <v-btn @click="$refs.datetimeselector1.showPicker()" color="success"
            >Select Date &amp; Time</v-btn
          ></v-col
        >
        <v-expand-x-transition>
          <v-col cols="12" v-if="appdt">
            <v-alert
              text
              prominent
              color="teal"
              icon="mdi-timer-check"
              border="left"
              ><h3>Appointment on : {{ turnTODate }}</h3>
            </v-alert>
          </v-col>
        </v-expand-x-transition>

        <!-- <v-col cols="6" sm="6"
                  ><v-text-field label="Time" type="time"></v-text-field
                ></v-col> -->
      </v-row>
      <v-row>
        <v-col cols="6" sm="6"> </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { UPDATE_ADRRESS_INFO_BY_KEY } from "@/store/storefunctions";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "DateTimeSelect",
  data: function () {
    return {
      date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10),
      menu1: false,
      time: null,
      menu2: false,
      menu3: false,
      appdt: null, //new Date(),
    };
  },
  validations: {
    date: {
      required,
      minValue(val) {
        return new Date(val) > new Date();
      },
    },
  },
  methods: {
    allowedHours: (v) => v >= 6 && v <= 23,
    allowedStep: (m) => m % 15 === 0,
    ...mapActions({
      // saveCustomerInfo: `${SET_CUSTOMER_INFO}`,
      // saveBookingInfo: `${SET_BOOKING_INFO}`,
      saveDateTime: `${UPDATE_ADRRESS_INFO_BY_KEY}`,
    }),
    dtChanged() {
      if (!this.$v.date.$invalid) {
        this.saveDateTime({ key: "datetime", value: this.appdt });
      }
      //show error
    },
  },
  computed: {
    tomo() {
      var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      return (
        currentDate.toISOString().substring(0, 10) +
        "" +
        currentDate.toISOString().substring(10)
      );
    },
    turnTODate() {
      if (!this.appdt) return "";

      let retdate = new Date(this.appdt);
      //   return retdate.toLocaleString();
      return (
        retdate.toDateString() + ", " + retdate.toTimeString().substring(0, 5)
      );
    },

    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("Appointment date is required.");
      !this.$v.date.minValue && errors.push("Please select a valid date");
      return errors;
    },
  },
};
</script>

<style>
input[type="datetime-local"] {
  visibility: hidden;
  position: absolute;
}
</style>
