<template>
  <v-row style="margin-top: 128px">
    <v-col sm="12" md="8"
      ><v-sheet rounded="lg" min-height="50vh" elevation="1" class="pa-10">
        <h4>Payment Details</h4>
        <v-divider></v-divider>
        <template>
          <v-card
            class="overflow-hidden mt-5"
            color="light-blue lighten-5"
            light
            max-width="650px"
          >
            <v-toolbar flat color="light-blue accent-1">
              <v-icon>mdi-credit-card-outline</v-icon>
              <v-toolbar-title class="font-weight-light">
                Pay with Credit/ Debit Card
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Name on the card"
                    v-model="cardname"
                    :error-messages="cardnameErrors"
                    @input="$v.cardname.$touch()"
                    @blur="$v.cardname.$touch()"
                  ></v-text-field
                ></v-col>
                <v-col sm="6">
                  <v-text-field
                    outlined
                    label="Card Number"
                    v-mask="'#### #### #### ####'"
                    v-model="cardno"
                    :error-messages="cardnoErrors"
                    @input="$v.cardno.$touch()"
                    @blur="$v.cardno.$touch()"
                  ></v-text-field
                ></v-col>
                <v-col sm="3"
                  ><v-text-field
                    outlined
                    label="Expiry"
                    v-mask="'##/##'"
                    v-model="cardexp"
                    placeholder="mm/yy"
                    :error-messages="cardexpErrors"
                    @input="$v.cardexp.$touch()"
                    @blur="$v.cardexp.$touch()"
                  ></v-text-field></v-col
                ><v-col sm="3">
                  <v-text-field
                    outlined
                    type="text"
                    label="CVV"
                    v-model="cvv"
                    v-mask="'###'"
                    :error-messages="cvvErrors"
                    @input="$v.cvv.$touch()"
                    @blur="$v.cvv.$touch()"
                  ></v-text-field
                ></v-col>
                <v-col cols="12"> <h5>Billing Address</h5></v-col>
                <v-col sm="6">
                  <v-text-field
                    outlined
                    label="Street address"
                    v-model="street"
                    :error-messages="streetErrors"
                    @input="$v.street.$touch()"
                    @blur="$v.street.$touch()"
                  ></v-text-field>
                </v-col>
                <!--v-col sm="6">
                  <v-text-field
                    label="City"
                    v-model="city"
                    :error-messages="cityErrors"
                    @input="$v.city.$touch()"
                    @blur="$v.city.$touch()"
                  ></v-text-field> </v-col
                ><v-col sm="6">
                  <v-text-field
                    label="County"
                    v-model="county"
                    :error-messages="countyErrors"
                    @input="$v.county.$touch()"
                    @blur="$v.county.$touch()"
                  ></v-text-field> </v-col-->
                <v-col sm="6">
                  <v-text-field
                    outlined
                    label="Postcode"
                    v-model="postcode"
                    :error-messages="postcodeErrors"
                    @input="$v.postcode.$touch()"
                    @blur="$v.postcode.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                color="success"
                dark
                class="mt-15 float_right"
                @click="processPayment"
                ><v-icon class="mr-1">mdi-lock-check-outline</v-icon> Pay
                (£50.00)</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-sheet></v-col
    >
    <v-col sm="12" md="4"
      ><v-sheet rounded="lg" min-height="50vh" elevation="1" class="pa-5">
        <h4>Summary</h4>
        <v-divider class="mb-10"></v-divider>
        <v-icon color="teal darken-2">mdi-account-circle</v-icon>
        Client:&nbsp;<span v-if="customerInf" class="summary">{{
          `${customerInf.title} ${customerInf.name} ${customerInf.lastname}`
        }}</span>
        <br />
        <v-icon color="teal darken-2">mdi-clipboard-text-clock</v-icon> Booking
        Date &amp; Time :&nbsp;<span v-if="bookingInf" class="summary">{{
          getFormatedDate
        }}</span>
        <br />
        <v-icon color="teal darken-2">mdi-map-marker</v-icon>
        Property Address:&nbsp;<span v-if="bookingInf" class="summary"
          >{{ bookingInf.street }},&nbsp;{{ bookingInf.town }}
          {{ bookingInf.postcode }}
        </span>
        <br />
        <v-icon color="teal darken-2">mdi-vacuum</v-icon>Alex Ahabwe's Services
        sought:&nbsp;
        <span v-if="servicesInfo" class="summary">
          <!-- <ul v-for="item in servicesInfo" :key="item.id">
            <li>{{ item.title }}</li>
          </ul> -->
          <template v-for="item in servicesInfo">
            <span :key="item.id"
              ><v-chip outlined small color="green"
                ><v-icon left small>mdi-spray-bottle</v-icon
                >{{ item.title }}</v-chip
              >
            </span>
          </template> </span
        ><br />

        <v-icon color="teal darken-2">mdi-basket</v-icon>&nbsp;Estimated
        Total:&nbsp;<span v-if="basketTot" class="summary"
          >£{{ basketTot.toFixed(2) }}</span
        >
        <br />
        <v-icon color="teal darken-2">mdi-cash-register</v-icon> Reservation Fee
        Due Today:&nbsp;
        <strong>£50.00</strong>

        <div>
          <v-btn
            large
            color="orange"
            dark
            class="mt-15 float_left"
            to="/bookingview"
            ><v-icon>mdi-arrow-left</v-icon>Booking</v-btn
          >
        </div>
      </v-sheet></v-col
    >
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  required,
  numeric,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import {
  SET_PAYMENT_INFO,
  GET_CART_ITEMS_FULL,
  CART_TOTAL_VALUE,
  GET_BOOKING_INFO,
  GET_CUSTOMER_INFO,
} from "@/store/storefunctions.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PaymentView",
  directives: { mask },
  data() {
    return {
      cardname: null, //40 chras
      cardno: null,
      cardexp: null,
      cvv: null,
      street: null,
      city: null,
      county: null,
      postcode: null,
    };
  },
  validations: {
    cardname: { required, minLength: minLength(2), maxLength: maxLength(40) }, //new legnth 21 -22
    cardno: {
      required,
      minLength: minLength(16),
      maxLength: maxLength(19), //19 with spaces
    }, //american express min length 15
    cardexp: { required },
    cvv: {
      required,
      numeric,
      minLength: minLength(3),
      maxLength: maxLength(4),
    }, // amex cvv 4 digits
    street: { required, maxLength: maxLength(75) },
    city: { required, maxLength: maxLength(75) },
    county: { required, maxLength: maxLength(75) },
    postcode: { required, minLength: minLength(6), maxLength: maxLength(8) },
  },
  methods: {
    ...mapActions({
      savePayInfo: `${SET_PAYMENT_INFO}`,
    }),
    processPayment() {
      //save info
      this.savePayInfo({
        cardname: this.cardname,
        longnumber: this.cardno,
        exp: this.cardexp,
        cvv: this.cvv,
        street: this.street,
        city: this.city,
        county: this.county,
        postcode: this.postcode,
      });
      //pricess payment,
      //probly show success page
      this.$router.push("/confview");
    },
  },
  computed: {
    ...mapGetters({
      customerInf: `${GET_CUSTOMER_INFO}`,
      bookingInf: `${GET_BOOKING_INFO}`,
      basketTot: `${CART_TOTAL_VALUE}`,
      servicesInfo: `${GET_CART_ITEMS_FULL}`,
    }),
    getFormatedDate() {
      if (this.bookingInf.datetime) {
        return this.bookingInf.datetime.replace("T", " ");
      } else {
        return "Not Selected";
      }
    },
    cardnameErrors() {
      const errors = [];
      if (!this.$v.cardname.$dirty) return errors;
      !this.$v.cardname.required && errors.push("Name on the card required.");
      !this.$v.cardname.minLength &&
        errors.push("Name on the card too short. Min 2 characters requred.");
      !this.$v.cardname.maxLength &&
        errors.push("Name on the card is too long. Max 40 characters allowed");
      return errors;
    },
    cardnoErrors() {
      const errors = [];
      if (!this.$v.cardno.$dirty) return errors;
      !this.$v.cardno.required &&
        errors.push("16 Digit card number is required.");
      //!this.$v.cardno.numeric && errors.push("Only numbers allowed.");
      !this.$v.cardno.minLength &&
        errors.push("Card number is too short. Min 15 characters requred.");
      !this.$v.cardno.maxLength &&
        errors.push("Card number is too long. Max 16 characters allowed");
      return errors;
    },
    cardexpErrors() {
      const errors = [];
      if (!this.$v.cardexp.$dirty) return errors;
      !this.$v.cardexp.required && errors.push("Card expiry date is required.");
      //!this.$v.mob.numeric && errors.push("Only numbers allowed.");
      /*!this.$v.mob.maxLength &&
        errors.push("Mobile number is too long. Max 11 characters allowed");*/
      return errors;
    },
    cvvErrors() {
      const errors = [];
      if (!this.$v.cvv.$dirty) return errors;
      !this.$v.cvv.required && errors.push("CVV number is required.");
      !this.$v.cvv.numeric && errors.push("Only numbers allowed.");
      !this.$v.cvv.minLength &&
        errors.push("CVV is too short. Min 3 characters required");
      !this.$v.cvv.maxLength &&
        errors.push("CVV is too long. Max 4 characters allowed");
      return errors;
    },
    streetErrors() {
      const errors = [];
      if (!this.$v.street.$dirty) return errors;
      !this.$v.street.required && errors.push("Street name is required.");
      !this.$v.street.maxLength &&
        errors.push("Street name is too long. Max 75 characters allowed");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("City name is required.");
      !this.$v.city.maxLength &&
        errors.push("City name is too long. Max 75 characters allowed");
      return errors;
    },
    countyErrors() {
      const errors = [];
      if (!this.$v.county.$dirty) return errors;
      !this.$v.county.required && errors.push("County is required.");
      !this.$v.county.maxLength &&
        errors.push("County name is too long. Max 11 characters allowed");
      return errors;
    },
    postcodeErrors() {
      const errors = [];
      if (!this.$v.postcode.$dirty) return errors;
      !this.$v.postcode.required && errors.push("Postcode number is required.");
      !this.$v.postcode.minLength &&
        errors.push("Postcode is too short. Min 6 characters requred.");
      !this.$v.postcode.maxLength &&
        errors.push("Postcode is too long. Max 8 characters allowed");
      return errors;
    },
  },
};
</script>

<style>
.summary {
  font-weight: 600;
}
</style>
