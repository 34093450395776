export const SERVICESLIST = "SERVICESLIST";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const GET_CART_ITEMS_FULL = "GET_CART_ITEMS_FULL";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAR_CART = "CLEAR_CART";
export const CART_TOTAL_ITEMS = "CART_TOTAL_ITEMS";
export const CART_TOTAL_VALUE = "CART_TOTAL_VALUE";
export const SET_BOOKING_INFO = "SET_BOOKING_INFO";
export const GET_BOOKING_INFO = "GET_BOOKING_INFO";
export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";
export const SET_PAYMENT_INFO = "SET_PAYMENT_INFO";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";
export const UPDATE_ADRRESS_INFO_BY_KEY = "UPDATE_ADRRESS_INFO_BY_KEY";
export const IS_ADRRESS_INFO_VALID = "IS_ADRRESS_INFO_VALID";
export const IS_DATETIME_INFO_VALID = "IS_DATETIME_INFO_VALID";
