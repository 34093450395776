<template>
  <v-row style="margin-top: 128px">
    <v-spacer></v-spacer>

    <v-col sm="12" md="8"
      ><v-sheet rounded="lg" min-height="50vh" elevation="1" class="pa-10">
        <!-- <v-img src="../assets/BECOServicessvg.svg" width="30%" /> -->
        <span class="navbar-brand">Alex Ahabwe</span>
        <v-divider class="my-3"></v-divider>
        <p>
          Hey
          <strong>{{ `${cusInfo.name}` }}</strong
          >,
        </p>
        <span style="color: orange">
          <v-icon color="orange">mdi-check</v-icon> Your booking placed
          successfuly </span
        ><br />
        <p class="mt-3">
          Thanks for your custome! We will send an email confirmation shortly.
        </p>
        <v-divider class="my-3"></v-divider>
        <p>Order: {order number}</p>
        <p>Reservation Deposit Paid: £50.00</p>
      </v-sheet></v-col
    >
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import { GET_CUSTOMER_INFO } from "@/store/storefunctions";
import { mapGetters } from "vuex";
export default {
  name: "ConfView",
  computed: {
    ...mapGetters({
      cusInfo: `${GET_CUSTOMER_INFO}`,
    }),
  },
};
</script>

<style>
.navbar-brand {
  color: #0e38fc;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}
</style>
