import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import ServicesList from "../components/ServicesList.vue";
import BasketView from "@/views/BasketView.vue";
import BookingView from "@/views/BookingView.vue";
import PaymentView from "@/views/PaymentView.vue";
import ConfView from "@/views/ConfView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: ServicesList,
  },
  {
    path: "/cartview",
    name: "cartview",
    component: BasketView,
  },
  {
    path: "/bookingview",
    name: "BookingView",
    component: BookingView,
  },
  {
    path: "/paymentview",
    name: "PaymentView",
    component: PaymentView,
  },
  {
    path: "/confview",
    name: "ConfView",
    component: ConfView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
