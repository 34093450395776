import Vue from "vue";
import Vuex from "vuex";
import services from "../store/dd.js";
import {
  SERVICESLIST,
  GET_CART_ITEMS,
  REMOVE_ITEM,
  ADD_TO_CART,
  CART_TOTAL_ITEMS,
  GET_CART_ITEMS_FULL,
  CART_TOTAL_VALUE,
  GET_BOOKING_INFO,
  GET_CUSTOMER_INFO,
  GET_PAYMENT_INFO,
  SET_BOOKING_INFO,
  SET_CUSTOMER_INFO,
  SET_PAYMENT_INFO,
  UPDATE_ADRRESS_INFO_BY_KEY,
  IS_ADRRESS_INFO_VALID,
  IS_DATETIME_INFO_VALID,
} from "../store/storefunctions.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    servicesList: services.services,
    cartItems: [], //just list of ID in shopping cart
    customerInfo: {},
    bookingInfo: {
      datetime: null,
      door: null,
      street: null,
      area: null,
      town: null,
      postcode: null,
    },
    paymentInfo: {},
  },
  getters: {
    [SERVICESLIST]: (state) => state.servicesList,
    [GET_CART_ITEMS]: (state) => state.cartItems,
    [CART_TOTAL_ITEMS]: (state) => state.cartItems.length,
    [GET_CART_ITEMS_FULL]: (state) => {
      let qnt = {};
      state.cartItems.forEach((x) => {
        qnt[x] = (qnt[x] || 0) + 1;
      });
      //obj with items and quantities
      let qunatified = [];

      for (const [key, value] of Object.entries(qnt)) {
        let qobj = state.servicesList.find((service) => service.id == key);
        qobj.quantity = value;
        qunatified.push(qobj);
      }
      return qunatified;
    },
    [CART_TOTAL_VALUE]: (state, getters) => {
      let tot = 0;
      getters.GET_CART_ITEMS_FULL.forEach((element) => {
        tot += element.price * element.quantity;
      });
      return tot;
    },
    [GET_CUSTOMER_INFO]: (state) => state.customerInfo,
    [GET_BOOKING_INFO]: (state) => state.bookingInfo,
    [GET_PAYMENT_INFO]: (state) => state.paymentInfo,
    [IS_ADRRESS_INFO_VALID]: (state) => {
      if (
        state.bookingInfo.door == "" ||
        state.bookingInfo.door == null ||
        state.bookingInfo.street == "" ||
        state.bookingInfo.street == null ||
        state.bookingInfo.town == "" ||
        state.bookingInfo.town == null ||
        state.bookingInfo.postcode == "" ||
        state.bookingInfo.postcode == null
      ) {
        return false;
      } else {
        return true;
      }
    },
    [IS_DATETIME_INFO_VALID]: (state) => {
      if (
        state.bookingInfo.datetime == "" ||
        state.bookingInfo.datetime == null
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mutations: {
    [ADD_TO_CART](state, payload) {
      state.cartItems.push(payload);
    },
    [REMOVE_ITEM](state, index) {
      state.cartItems.splice(index, 1); //remove 1 elemet at given index
    },
    [SET_CUSTOMER_INFO](state, payload) {
      state.customerInfo = {
        title: payload.title,
        name: payload.name,
        lastname: payload.lastname,
        mobile: payload.mobile,
        landline: payload.landline,
        email: payload.email,
      };
    },
    [SET_BOOKING_INFO](state, payload) {
      state.bookingInfo = {
        date: payload.date,
        time: payload.time,
        door: payload.door,
        street: payload.street,
        area: payload.locality,
        town: payload.town,
        postcode: payload.postcode,
      };
    },
    [SET_PAYMENT_INFO](state, payload) {
      state.paymentInfo = {
        nameOnCard: payload.cardname,
        num: payload.longnumber,
        expiery: payload.exp,
        cvv: payload.cvv,
        street: payload.street,
        city: payload.city,
        county: payload.county,
        postcode: payload.postcode,
      };
    },
    [UPDATE_ADRRESS_INFO_BY_KEY](state, payload) {
      state.bookingInfo[payload.key] = payload.value;
    },
  },
  actions: {
    [ADD_TO_CART]({ commit }, payload) {
      commit("ADD_TO_CART", payload);
    },
    [REMOVE_ITEM]({ commit, state }, payload) {
      //just need to get last index of element and commit index
      let i = state.cartItems.lastIndexOf(payload);
      commit("REMOVE_ITEM", i);
    },
    [SET_CUSTOMER_INFO]({ commit }, payload) {
      commit("SET_CUSTOMER_INFO", payload);
    },
    [SET_BOOKING_INFO]({ commit }, payload) {
      commit("SET_BOOKING_INFO", payload);
    },
    [SET_PAYMENT_INFO]({ commit }, payload) {
      commit("SET_PAYMENT_INFO", payload);
    },
    [UPDATE_ADRRESS_INFO_BY_KEY]({ commit }, payload) {
      commit("UPDATE_ADRRESS_INFO_BY_KEY", payload);
    },
  },
  modules: {},
});
