<template>
  <v-app>
    <v-app-bar
      app
      absolute
      color="#43a047"
      dark
      shrink-on-scroll
      prominent
      src="./assets/nav-bg.jpg"
      fade-img-on-scroll
      scroll-target="#scrolling-techniques-5"
      class="mb-5"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(55,236,186,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-app-bar-title>Services</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <!-- <v-icon>mdi-account-star</v-icon> -->
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
      <v-btn icon to="/">
        <!-- <v-icon>mdi-store</v-icon> -->
        <v-icon>mdi-store-outline</v-icon>
      </v-btn>

      <v-btn icon to="cartview">
        <v-badge
          color="red darken-1"
          top
          right
          overlap
          :content="itemTotal"
          :value="itemTotal"
          ><v-icon>mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-5"
      class="overflow-y-auto mt-20"
      max-height="100vh"
      ><v-container>
        <router-view />
      </v-container>
    </v-sheet>

    <!-- <v-sheet>
     
    </v-sheet> -->
  </v-app>
</template>

<script>
import { CART_TOTAL_ITEMS } from "@/store/storefunctions.js";
import { mapGetters } from "vuex";
export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      itemTotal: `${CART_TOTAL_ITEMS}`,
    }),
  },
};
</script>
