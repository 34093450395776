<template>
  <!-- <v-card class="overflow-hidden mt-5" color="light-blue lighten-5" light> -->
  <v-card class="overflow-hidden mt-5" color="light-blue lighten-5" light>
    <!-- <v-toolbar flat color="light-blue accent-1"> -->
    <v-toolbar flat color="light-blue accent-1">
      <v-icon>mdi-home-clock</v-icon>
      <v-toolbar-title class="font-weight-light">
        Property Details (where Services are required)
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <!--v-row>
        <v-col cols="12">
          <v-input label="Appointment Date and Time" style="border-bottom: 1px"
            ><input
              v-model="appdt"
              :min="tomo"
              ref="datetimeselector1"
              type="datetime-local"
          /></v-input>
          <v-btn @click="$refs.datetimeselector1.showPicker()">open</v-btn>
        </v-col>
        <v-col cols="12">
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="false"
            :return-value.sync="appdt"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="appdt"
                label="Appointment Date and Time"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="(on, testFunc())"
                @click="testFunc()"
              ></v-text-field>
            </template>
            <input
              v-model="appdt"
              :min="tomo"
              ref="datetimeselector"
              type="datetime-local"
              @input="$refs.menu3.save(appdt)"
            />
          </v-menu>
        </v-col>
      </v-row-->
      <v-row>
        <v-col cols="6" sm="6">
          <!--v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="dateErrors"
                @input="$v.date.$touch()"
                @blur="$v.date.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable :min="tomo">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu-->
          <!--v-text-field label="Date" type="date" min></v-text-field
                --></v-col
        >
        <!-- <v-col cols="6" sm="6"
                  ><v-text-field label="Time" type="time"></v-text-field
                ></v-col> -->
        <!--v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="time"
              class="mt-4 mr-3"
              type="time"
              label="Time"
              append-icon="mdi-clock-outline"
              readonly
              v-on="on"
              :error-messages="timeErrors"
              @input="$v.time.$touch()"
              @blur="$v.time.$touch()"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="time"
            :allowed-hours="allowedHours"
            :allowed-minutes="allowedStep"
            class="mt-1"
            @click:minute="$refs.menu2.save(time)"
          ></v-time-picker>
        </v-menu-->
      </v-row>
      <!--input
                label="Appointment Date and time"
                type="datetime-local"
                :min="tomo"
              /-->
      <v-autocomplete
        label="Type in your address to autocomplete"
        prepend-icon="mdi-home-map-marker"
        type="text"
        v-model="searchField"
        :items="items"
        :loading="isLoading"
        :search-input.sync="searchKey"
        hide-no-data
        hide-selected
        return-object
        item-text="address"
        item-value="id"
        clearable
      ></v-autocomplete>
      <v-text-field
        label="House Number or Name"
        type="text"
        v-model="door"
        :error-messages="doorErrors"
        @input="$v.door.$touch()"
        @blur="$v.door.$touch()"
        :loading="isFilling"
      ></v-text-field>
      <v-text-field
        label="Street Name"
        type="text"
        v-model="addline1"
        :error-messages="addline1Errors"
        @input="$v.addline1.$touch()"
        @blur="$v.addline1.$touch()"
        :loading="isFilling"
      ></v-text-field>
      <v-text-field
        label="Area"
        type="text"
        v-model="addline2"
        :error-messages="addline2Errors"
        @input="$v.addline2.$touch()"
        @blur="$v.addline2.$touch()"
        :loading="isFilling"
      ></v-text-field>
      <v-text-field
        label="Town/City"
        type="text"
        v-model="town"
        :error-messages="townErrors"
        @input="$v.town.$touch()"
        @blur="$v.town.$touch()"
        :loading="isFilling"
      ></v-text-field>
      <v-text-field
        label="Postcode"
        type="text"
        v-model="postcode"
        :error-messages="postcodeErrors"
        @input="$v.postcode.$touch()"
        @blur="$v.postcode.$touch()"
        :loading="isFilling"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { UPDATE_ADRRESS_INFO_BY_KEY } from "@/store/storefunctions";
export default {
  name: "PropertyAddress",
  data: function () {
    return {
      searchField: "",
      searchKey: "",
      isLoading: false,
      entries: [],
      //
      door: null,
      addline1: null,
      addline2: null,
      town: null,
      postcode: null,
      isFilling: false,
    };
  },
  validations: {
    door: { required, maxLength: maxLength(75) },
    addline1: { required, maxLength: maxLength(75) },
    addline2: { maxLength: maxLength(75) },
    town: { required, maxLength: maxLength(75) },
    postcode: { required, minLength: minLength(6), maxLength: maxLength(9) },
  },
  computed: {
    //addressseach
    items() {
      if (!this.entries.length > 0) return [];
      let ret = [];
      this.entries.forEach((element) => {
        ret.push({ id: element.id, address: element.address });
      });
      console.log(ret);
      return ret;
      /*return this.entries.map((entry) => {
        const Address = entry.address;
        return Object.assign({}, entry.id, { Address });
      });*/
    },

    //form errors
    doorErrors() {
      const errors = [];
      if (!this.$v.door.$dirty) return errors;
      !this.$v.door.required &&
        errors.push("House Number or Name is required.");
      !this.$v.door.maxLength &&
        errors.push(
          "House Number or Name is too Long. Max 75 characters allowed."
        );
      return errors;
    },
    addline1Errors() {
      const errors = [];
      if (!this.$v.addline1.$dirty) return errors;
      !this.$v.addline1.required && errors.push("Street Name is required.");
      !this.$v.addline1.maxLength &&
        errors.push("Street Name is too Long. Max 75 characters allowed.");
      return errors;
    },
    addline2Errors() {
      const errors = [];
      if (!this.$v.addline2.$dirty) return errors;
      !this.$v.addline2.maxLength &&
        errors.push("Area name is too Long. Max 75 characters allowed.");
      return errors;
    },
    townErrors() {
      const errors = [];
      if (!this.$v.town.$dirty) return errors;
      !this.$v.town.required && errors.push("Town/City is required.");
      !this.$v.town.maxLength &&
        errors.push("Town/City name is too long. Max 75 characters allowed.");
      return errors;
    },
    postcodeErrors() {
      const errors = [];
      if (!this.$v.postcode.$dirty) return errors;
      !this.$v.postcode.required && errors.push("Postcode is required.");
      !this.$v.postcode.maxLength &&
        errors.push("Postcode is too long. Max 8 characters allowed.");
      return errors;
    },
  },
  methods: {
    startSeach() {},
    ...mapActions({
      updateRec: `${UPDATE_ADRRESS_INFO_BY_KEY}`,
    }),
    addline1Change() {},
    addline2Change() {},
    townChange() {},
    postcodeChange() {},
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    searchKey(val) {
      //ignor null
      if (!val) return;
      //Items already been loaded
      //if (this.items.length > 0) return;
      //items have already been requested
      if (this.isLoading) return;

      let strval = this.searchKey + "";
      console.log(val);

      //only start if more than 3 characters
      if (!this.searchKey.length > 2) return;
      this.isLoading = true;

      //lazily load input items
      fetch(
        `https://api.getAddress.io/autocomplete/${strval}?api-key=xfIifksq-UiqLdPkOlUCzg32938`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          const { suggestions } = res;
          this.entries = suggestions;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    // eslint-disable-next-line no-unused-vars
    searchField(val) {
      if (!this.searchField) return;
      this.isFilling = true;
      //send second request to fill fileds
      fetch(
        `https://api.getAddress.io/get/${this.searchField.id}?api-key=xfIifksq-UiqLdPkOlUCzg32938`
      )
        .then((res) => res.json())
        .then((res) => {
          this.door = res.building_name + res.building_number;
          this.addline1 = res.thoroughfare;
          this.addline2 = res.locality;
          this.town = res.town_or_city;
          this.postcode = res.postcode;
        })
        .finally(() => (this.isFilling = false));
    },
    door() {
      if (!this.$v.door.$invalid) {
        this.updateRec({ key: "door", value: this.door });
      }
    },
    addline1() {
      if (!this.$v.addline1.$invalid) {
        this.updateRec({ key: "street", value: this.addline1 });
      }
    },
    addline2() {
      if (!this.$v.addline2.$invalid) {
        this.updateRec({ key: "area", value: this.addline2 });
      }
    },
    town() {
      if (!this.$v.town.$invalid) {
        this.updateRec({ key: "town", value: this.town });
      }
    },
    postcode() {
      if (!this.$v.postcode.$invalid) {
        this.updateRec({ key: "postcode", value: this.postcode });
      }
    },
  },
};
</script>

<style></style>
