<template>
  <v-row style="margin-top: 128px">
    <v-col
      v-for="(n, i) in getServices"
      :key="i"
      col="1"
      sm="1"
      md="4"
      lg="4"
      xl="4"
    >
      <ServiceCard
        :id="n.id"
        :Title="n.title"
        :Price="n.price"
        :Description="n.desc"
        :Icon="n.icon"
        @added="toastOnAdd"
      />
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ sbText }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ServiceCard from "@/components/ServiceCard.vue";
import { SERVICESLIST } from "@/store/storefunctions.js";
export default {
  name: "ServicesList",
  components: { ServiceCard },
  data: () => ({
    snackbar: false,
    sbText: "added to basket",
  }),
  methods: {
    toastOnAdd: function (txt) {
      this.sbText = "✅ " + txt + " added to basket";
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      getServices: `${SERVICESLIST}`,
    }),
  },
};
</script>

<style></style>
